<template>
    <list-page
        page-title="Users"
        page-icon="mdi-account"
        collection-name="user"
        :items-per-page="20"
        add-dialog-title="Add User"
        is-filter
    />
</template>

<script>
import ListPage from '../../../components/pages/List';


export default {
    name: 'UserList',
    components: { ListPage },
    data() {
        return {};
    },
};
</script>

<style scoped></style>
